<template>
  <section class="section">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="wrapp-section-title section-title-center">
            <div class="section-subtitle">more info</div>
            <h2 class="section-title">{{title}}</h2>
          </div>
          <div class="content mb-5">
            <div class="img-style">
              <img src="/img/template/about-img.jpg" alt />
            </div>
          </div>
        </div>
        <div class="col">
          <p>Established in 2001, ObjectPower is a Software Development company with its own dedicated server farm providing cloud based services to our customers.</p>
          <ul>
            <li>
              Since our inception we have been working in the Travel Industry to provide aggregated pricing and availability data to support Online Travel Agents (OTAs). Processing millions of flights and package holidays daily from all departure points you can always be assured of an up to date view of the UK market.
              <br />
            </li>
            <li>
              As a Microsoft development partner we also provide generalised systems and software development on the .NET platform and associated technologies.
              <br />
              <br />
            </li>
            <li>We are also an Umbraco certified developer, and are proud to be providing solutions on this leading .NET based CMS platform.</li>
          </ul>
        </div>
      </div>

      <business />
      
      <div class="row">
        <div class="col">
          <!-- Begin our customers -->
          <section class="section">
            <div class="container">
              <div class="row items">
                <div class="col-12">
                  <div class="wrapp-section-title section-title-center">
                    <div class="section-subtitle">The best</div>
                    <h2 class="section-title">Our customers</h2>
                    <p
                      class="section-desc"
                    >Our customers have disrupted industries, opened new markets, and made countless lives better. We are privileged to work with hundreds of future-thinking businesses, including many of the world’s top hardware, software, and consumer brands.</p>
                  </div>
                </div>
              </div>

              <projects />
            </div>
          </section>
          <!-- End our customers -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "What we do",
  data() {
    return {
      title: "What we do"
    };
  }
};
</script>

<style>
</style>